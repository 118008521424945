import React, { type ReactNode, useContext, useMemo, useState } from 'react';

export type SwitcherTeam25ExperimentCohort = 'control' | 'test' | 'not-enrolled' | undefined;

interface useSwitcherTeam25ExperimentResult {
	cohort: SwitcherTeam25ExperimentCohort;
	setCohort: (cohort: SwitcherTeam25ExperimentCohort) => void;
}

type SwitcherExperimentContextProvider = {
	switcherTeam25ExperimentCohort: SwitcherTeam25ExperimentCohort;
	setSwitcherTeam25ExperimentCohort: (cohort: SwitcherTeam25ExperimentCohort) => void;
};

export const ExperimentContext = React.createContext<SwitcherExperimentContextProvider | undefined>(
	undefined,
);

export const SwitcherExperimentProvider = ({ children }: { children: ReactNode }) => {
	const [switcherTeam25ExperimentCohort, setSwitcherTeam25ExperimentCohort] =
		useState<SwitcherTeam25ExperimentCohort>();

	const contextValue = useMemo<SwitcherExperimentContextProvider>(
		() => ({
			switcherTeam25ExperimentCohort,
			setSwitcherTeam25ExperimentCohort,
		}),
		[switcherTeam25ExperimentCohort, setSwitcherTeam25ExperimentCohort],
	);

	return <ExperimentContext.Provider value={contextValue}>{children}</ExperimentContext.Provider>;
};

export const useExperimentProvider = () => useContext(ExperimentContext);

export const useSwitcherTeam25Experiment = (): useSwitcherTeam25ExperimentResult => {
	const experimentContext = useExperimentProvider();

	return {
		cohort: experimentContext?.switcherTeam25ExperimentCohort,
		setCohort: experimentContext ? experimentContext.setSwitcherTeam25ExperimentCohort : () => {},
	};
};

export const useIsSwitcherTeam25TestCohort = () => {
	const { cohort } = useSwitcherTeam25Experiment();
	return cohort === 'test';
};
