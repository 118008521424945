/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SectionContainer } from './section-container';
import SwitcherItem from './item';
import {
	useExperienceRenderAndMountMark,
	ExperienceMark,
} from '../../common/utils/experience-tracker';
import { useIntl } from 'react-intl-next';
import messages from './messages';

const iconSkeletonStyles = css({
	display: 'inline-block',
	width: 32,
	height: 32,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N20),
	borderRadius: token('border.radius.200', '8px'),
});

const lineSkeletonStyles = css({
	display: 'inline-block',
	width: 98,
	height: 10,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N20),
	borderRadius: token('border.radius', '3px'),
});

const iconSkeleton = <div css={iconSkeletonStyles} />;
const lineSkeleton = <div css={lineSkeletonStyles} />;
export default () => {
	useExperienceRenderAndMountMark(ExperienceMark.SWITCHER_SKELETON_MOUNT);
	const { formatMessage } = useIntl();
	return (
		<SectionContainer
			sectionId="skeleton"
			title={lineSkeleton}
			sectionRole="alert"
			aria-label={formatMessage(messages.skeletonLoaderAriaLabel)}
		>
			<SwitcherItem tag="li" icon={iconSkeleton} isDisabled aria-hidden="true">
				{lineSkeleton}
			</SwitcherItem>
			<SwitcherItem tag="li" icon={iconSkeleton} isDisabled aria-hidden="true">
				{lineSkeleton}
			</SwitcherItem>
			<SwitcherItem tag="li" icon={iconSkeleton} isDisabled aria-hidden="true">
				{lineSkeleton}
			</SwitcherItem>
		</SectionContainer>
	);
};
