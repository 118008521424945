export function invertRecordOfArrays<K extends PropertyKey, V extends PropertyKey>(
	record: Record<K, V[]>,
): Record<V, K[]> {
	const invertedRecord: Record<V, K[]> = {} as any;

	Object.entries<V[]>(record).forEach(([key, values]) => {
		values.forEach((value) => {
			if (!invertedRecord[value]) {
				invertedRecord[value] = [];
			}
			invertedRecord[value].push(key as K);
		});
	});

	return invertedRecord;
}
