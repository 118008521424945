/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, keyframes, jsx } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import * as colors from '@atlaskit/theme/colors';

interface Props {
	children: React.ReactNode;
	className?: string;
	tag?: keyof JSX.IntrinsicElements;
}

const fadeIn = keyframes({
	to: {
		opacity: 1,
	},
});

const fadeInStyles = css({
	animation: `${fadeIn} 500ms ease forwards`,
	opacity: 0,
	margin: 0,
	padding: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

export const FadeIn = ({ children, className, tag: Tag = 'div' }: Props) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<Tag css={fadeInStyles} className={className}>
		{children}
	</Tag>
);
