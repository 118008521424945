import type { UFOExperienceConfig } from './types';
import { ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { invertRecordOfArrays } from '../invert-record-of-arrays';

export enum Experience {
	RENDER_CUSTOM_LINKS_SECTION = 'render-custom-links-section',
	RENDER_JOIN_SECTION = 'render-join-section',
	RENDER_SWITCH_TO_SECTION = 'render-switch-to-section',
	RENDER_SWITCHER_EMPTY_STATE = 'render-switcher-empty-state',
}

export const EXPERIENCE_CONFIGS: Record<Experience, UFOExperienceConfig> = {
	[Experience.RENDER_CUSTOM_LINKS_SECTION]: {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
	[Experience.RENDER_JOIN_SECTION]: {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
	[Experience.RENDER_SWITCH_TO_SECTION]: {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
	[Experience.RENDER_SWITCHER_EMPTY_STATE]: {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
};

export enum ExperienceMark {
	API_APP_SWITCHER_BFF_AVAILABLE_PRODUCTS = 'api-app-switcher-bff-available-products',
	API_CONFLUENCE_CUSTOM_LINKS = 'api-confluence-custom-links',
	API_FLIGHT_DECK = 'api-flight-deck',
	API_JIRA_CUSTOM_LINKS = 'api-jira-custom-links',
	API_JOINABLE_SITES = 'api-joinable-sites',
	API_PERMISSIONS = 'api-permissions',
	SWITCHER_INITIAL_MOUNT = 'switcher-initial-mount',
	SWITCHER_SKELETON_MOUNT = 'switcher-skeleton-mount',

	// Mock for tests
	TEST_MARK = 'test-mark',
}

const allNonTestExperienceMarks = Object.values(ExperienceMark).filter(
	(mark) => mark !== ExperienceMark.TEST_MARK,
);

export const EXPERIENCES_TO_MARKS: Record<Experience, ExperienceMark[]> = {
	[Experience.RENDER_CUSTOM_LINKS_SECTION]: allNonTestExperienceMarks,
	[Experience.RENDER_JOIN_SECTION]: allNonTestExperienceMarks,
	[Experience.RENDER_SWITCH_TO_SECTION]: allNonTestExperienceMarks,
	[Experience.RENDER_SWITCHER_EMPTY_STATE]: allNonTestExperienceMarks,
};

export const MARKS_TO_EXPERIENCES = invertRecordOfArrays(EXPERIENCES_TO_MARKS);

export enum ExperienceMarkPhase {
	START = 'start',
	END = 'end',
}

export enum AbortReason {
	COMPONENT_UNMOUNTED = 'component-unmounted',
	WINDOW_UNLOADING = 'window-unloading',
}

export const SWITCHER_RENDER_START_PERFORMANCE_MARK = 'atlassianSwitcher.render.start';
