import type { SwitcherExperienceTrackerAPI } from './types';
import React, { createContext, useContext } from 'react';

const noop = () => {};

export const SwitcherExperienceTrackerContext = createContext<SwitcherExperienceTrackerAPI>({
	start: noop,
	success: noop,
	failure: noop,
	failureAll: noop,
	abort: noop,
	abortAll: noop,
	markStart: noop,
	markEnd: noop,
});

export const useExperienceTracker = () => useContext(SwitcherExperienceTrackerContext);

export interface WithExperienceTrackerProps {
	experienceTracker: SwitcherExperienceTrackerAPI;
}

export const withExperienceTracker = <P extends {}>(
	Component: React.ComponentType<P & WithExperienceTrackerProps>,
): React.ComponentType<P> => {
	const WithExperienceTracker = (props: P) => {
		const experienceTracker = useExperienceTracker();
		return <Component {...props} experienceTracker={experienceTracker} />;
	};
	WithExperienceTracker.displayName = `WithExperienceTracker(${Component.displayName})`;
	return WithExperienceTracker;
};
