import asDataProvider, {
	type DataProviderProps,
	type ProviderRetryConfig,
} from './as-data-provider';
import { fetchJson } from '../utils/fetch';
import { withCached, type WithCached } from '../utils/with-cached';
import { type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { type ExperienceMark } from '../utils/experience-tracker';

/**
 * Some items might be using the type `ExportedDataProvider` instead due to errors with
 * the generated documentation
 */
export type DataProvider<T, D = object> = {
	fetchMethod: WithCached<(param: D) => Promise<T>>;
	ProviderComponent: DataProviderProps<T> & any;
};

/**
 * `WithCached` within `DataProvider` breaks the documentation with error:
 * `Error: Missing converter for: TSMappedType` due to usage of Proxy function
 * so we are exporting a simpler type here just for the docs. There has been reported
 * on their repo already: https://github.com/atlassian/extract-react-types/issues/75
 */
export type ExportedDataProvider<T, D extends WithAnalyticsEventsProps = object> = {
	fetchMethod: (param: D) => Promise<T>;
	ProviderComponent: DataProviderProps<T> & any;
};

export const createProvider = <T>(
	name: string,
	experienceMark: ExperienceMark,
	url: string,
): DataProvider<T> => {
	const fetchMethod = withCached((param: object) => fetchJson<T>(url));

	return {
		fetchMethod,
		ProviderComponent: asDataProvider(
			name,
			experienceMark,
			fetchMethod,
			fetchMethod.cached,
			// fetch will only throw on a network exception,
			// so we can safely retry on all exceptions
			{
				shouldRetryOnException: () => true,
				intervalsMS: [50, 200, 1000],
			},
		),
	};
};

export const createProviderWithCustomFetchData = <T, D = object>(
	name: string,
	experienceMark: ExperienceMark,
	fetchData: (param: D) => Promise<T>,
	retryConfig?: ProviderRetryConfig,
): DataProvider<T, D> => {
	const fetchMethod = withCached((param: D) => fetchData(param));

	return {
		fetchMethod,
		ProviderComponent: asDataProvider<D, T>(
			name,
			experienceMark,
			fetchMethod,
			fetchMethod.cached,
			retryConfig,
		),
	};
};
