import React from 'react';
import { NAVIGATION_CHANNEL, OPERATIONAL_EVENT_TYPE } from '../utils/analytics';
import {
	type AnalyticsEventPayload,
	type WithAnalyticsEventsProps,
	withAnalyticsEvents,
} from '@atlaskit/analytics-next';
import { errorToReason, type Reason } from '../utils/error-to-reason';
import { retryOnException } from '../utils/retry-operation';
import {
	withExperienceTracker,
	type WithExperienceTrackerProps,
	type ExperienceMark,
} from '../utils/experience-tracker';

const DATA_PROVIDER_SUBJECT = 'atlassianSwitcherDataProvider';

export enum Status {
	LOADING = 'loading',
	COMPLETE = 'complete',
	ERROR = 'error',
}

export interface ResultComplete<T> {
	status: Status.COMPLETE;
	data: T;
}

export interface ResultLoading {
	status: Status.LOADING;
	data: null;
}

export interface ResultError {
	status: Status.ERROR;
	error: any;
	data: null;
}

export const createResultComplete = <T,>(data: T): ResultComplete<T> => ({
	status: Status.COMPLETE,
	data,
});

export const isComplete = <T,>(result: ProviderResult<T>): result is ResultComplete<T> =>
	result.status === Status.COMPLETE;

export const isError = <T,>(result: ProviderResult<T>): result is ResultError =>
	result.status === Status.ERROR;

export const isLoading = <T,>(result: ProviderResult<T>): result is ResultLoading =>
	result.status === Status.LOADING;

export const hasLoaded = <T,>(result: ProviderResult<T>) => result.status !== Status.LOADING;

export type ProviderResult<T> = ResultComplete<T> | ResultLoading | ResultError;

interface PropsToPromiseMapper<P, D> extends Function {
	(props: P): Promise<D>;
}

interface PropsToValueMapper<P, D> {
	(props: P): D;
}

type ProviderRenderer<D> = (props: ProviderResult<D>) => React.ReactNode;

export type ProviderRetryConfig = {
	shouldRetryOnException?: (exception: any) => boolean;
	customErrorToReason?: (exception: any) => Reason;
	intervalsMS?: number[];
};

export interface DataProviderProps<D> {
	children: ProviderRenderer<D>;
}

export default function <P, D>(
	name: string,
	experienceMark: ExperienceMark,
	mapPropsToPromise: PropsToPromiseMapper<Readonly<P>, D>,
	mapPropsToInitialValue?: PropsToValueMapper<Readonly<P>, D | void>,
	retryConfig?: ProviderRetryConfig,
	shouldUpdate?: (prevProps: P, nextProps: P) => boolean,
) {
	const getInitialState = (props: Readonly<P>): ProviderResult<D> => {
		if (mapPropsToInitialValue) {
			const initialValue = mapPropsToInitialValue(props);
			if (initialValue !== undefined) {
				return {
					status: Status.COMPLETE,
					data: initialValue,
				};
			}
		}

		return {
			status: Status.LOADING,
			data: null,
		};
	};

	type OuterProps = P & DataProviderProps<D>;

	type InnerProps = { params: P } & DataProviderProps<D> &
		WithAnalyticsEventsProps &
		WithExperienceTrackerProps;
	type States = ProviderResult<D>;

	class DataProvider extends React.Component<InnerProps, States> {
		acceptResults = true;
		state = getInitialState(this.props.params);

		static displayName = `DataProvider(${name})`;

		componentWillUnmount() {
			/**
			 * Promise resolved after component is unmounted to be ignored
			 */
			this.acceptResults = false;
		}

		componentDidMount() {
			this.props.experienceTracker.markStart(experienceMark);
			this.fetchData();
		}

		componentDidUpdate(prevProps: InnerProps) {
			if (shouldUpdate?.(prevProps.params, this.props.params)) {
				this.fetchData();
			}
		}

		private fetchData() {
			retryOnException(() => mapPropsToPromise(this.props.params), {
				intervalsMS: retryConfig?.intervalsMS || [],
				shouldRetryOnException: retryConfig?.shouldRetryOnException,
				onRetry: (previousException, retryCount) => {
					this.onRetry(previousException, retryCount);
				},
			})
				.then((result) => {
					this.onResult(result);
				})
				.catch((error) => {
					this.onError(error);
				});
		}

		private fireOperationalEvent = (payload: AnalyticsEventPayload) => {
			if (this.props.createAnalyticsEvent) {
				this.props
					.createAnalyticsEvent({
						eventType: OPERATIONAL_EVENT_TYPE,
						actionSubject: DATA_PROVIDER_SUBJECT,
						...payload,
						attributes: {
							...payload.attributes,
							outdated: !this.acceptResults,
						},
					})
					.fire(NAVIGATION_CHANNEL);
			}
		};

		onResult(value: D) {
			if (this.acceptResults) {
				this.setState({
					data: value,
					status: Status.COMPLETE,
				});
			}

			this.props.experienceTracker.markEnd(experienceMark);

			this.fireOperationalEvent({
				action: 'receivedResult',
				actionSubjectId: name,
				attributes: {
					provider: name,
				},
			});
		}

		onRetry(error: any, retryCount: number) {
			this.fireOperationalEvent({
				action: 'retried',
				actionSubjectId: name,
				attributes: {
					provider: name,
					reason: retryConfig?.customErrorToReason
						? retryConfig.customErrorToReason(error)
						: errorToReason(error),
					retryCount,
				},
			});
		}

		onError(error: any) {
			/**
			 * Do not transition from "complete" state to "error"
			 */
			if (this.acceptResults && !isComplete(this.state)) {
				this.setState({
					error,
					status: Status.ERROR,
					data: null,
				});
			}

			this.fireOperationalEvent({
				action: 'failed',
				actionSubjectId: name,
				attributes: {
					provider: name,
					reason: errorToReason(error),
				},
			});
		}

		render() {
			return (this.props.children as ProviderRenderer<D>)(this.state);
		}
	}

	// In prefetch, we start with empty args {}, but when getting data from the cache in the data provider, extra args are injected by HOC withAnalyticsEvents and withExperienceTracker
	// This results in a cache miss due to a key mismatch.
	// The workaround here is to use a wrapper (OuterComponent) that passes the original props as a separate prop(params) to the underlying component(InnerComponent).
	// For more info: https://product-fabric.atlassian.net/browse/CXP-2697
	const InnerComponent = withAnalyticsEvents()(withExperienceTracker(DataProvider));

	const OuterComponent = ({ children, ...params }: OuterProps) => {
		return <InnerComponent params={params as P}>{children}</InnerComponent>;
	};

	return OuterComponent;
}
