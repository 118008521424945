import React from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { type WithAnalyticsEventsProps, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { type SwitcherItemType } from '../../common/utils/links';
import { createAndFireNavigationEvent, SWITCHER_ITEM_SUBJECT } from '../../common/utils/analytics';
import { FormattedMessage } from 'react-intl-next';

type ItemLinkOwnProps = {
	actionSubject?: string;
	iconAfter?: ButtonProps['iconAfter'];
	testId?: ButtonProps['testId'];
};

type ItemLinkProps = Pick<
	SwitcherItemType,
	Exclude<Exclude<keyof SwitcherItemType, 'iconKey'>, 'key'>
> &
	WithAnalyticsEventsProps &
	ItemLinkOwnProps & { onClick?: Function };

const ItemLink = ({ label, href, testId, onClick, ariaLabel }: ItemLinkProps) => {
	const buttonProps: ButtonProps = {
		appearance: 'link',
		href,
		className: 'section-link',
		target: '_blank',
		rel: 'noopener noreferrer',
		iconAfter: <ShortcutIcon LEGACY_size="small" color="currentColor" label="" />,
		onClick: onClick ? (e, a) => onClick(e, a) : undefined,
		testId,
	};

	if (ariaLabel) {
		return (
			<FormattedMessage {...ariaLabel}>
				{(placeholder) => (
					<Button {...buttonProps} aria-label={String(placeholder)}>
						{label}
					</Button>
				)}
			</FormattedMessage>
		);
	}

	return <Button {...buttonProps}>{label}</Button>;
};

export default withAnalyticsEvents({
	onClick: createAndFireNavigationEvent({
		eventType: UI_EVENT_TYPE,
		action: 'clicked',
		actionSubject: SWITCHER_ITEM_SUBJECT,
	}),
})(ItemLink);
