/* eslint-disable no-console */
import { useExperienceTracker } from './context';
import { useLayoutEffect } from 'react';
import { type Experience, type ExperienceMark } from './constants';
import { useOnceImmediately } from '../hooks';

export function useExperienceStart(experienceName: Experience, customStartTime?: number) {
	const experienceTracker = useExperienceTracker();
	useOnceImmediately(() => experienceTracker.start(experienceName, customStartTime));
}

export function useExperienceSuccess(experience: Experience, attributes?: object) {
	const experienceTracker = useExperienceTracker();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => experienceTracker.success(experience, attributes), []);
}

export function useExperienceRenderAndMountMark(mark: ExperienceMark) {
	const experienceTracker = useExperienceTracker();

	useOnceImmediately(() => experienceTracker.markStart(mark));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => experienceTracker.markEnd(mark), []);
}
