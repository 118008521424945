import { Product, type SwitcherProductType } from '../../types';

export enum Environment {
	Staging = 'stg',
	Production = 'prod',
}

/**
 * Resolves product environment type,
 * Falls back to Environment.Staging
 *
 * !!! Trello only, other products to be added
 *
 * @param origin
 */
export const getEnvName = (origin: string | undefined = window?.location?.origin) =>
	['https://trello.com'].includes(origin) ? Environment.Production : Environment.Staging;

const getProduct = (product: Product | SwitcherProductType | undefined) => {
	if (product) {
		const lowerJira = Product.JIRA.toLowerCase();
		const lowerProduct = product.toLowerCase();
		if (lowerProduct.includes(lowerJira)) {
			return lowerJira;
		}

		return lowerProduct;
	}
};

export const getLoginUrl = (
	productType: Product | SwitcherProductType | undefined,
	env: Environment = getEnvName(),
	continueUrl: string = String(window.location),
	loginHint?: string,
) => {
	const baseUrl =
		env === Environment.Production
			? 'https://id.atlassian.com/login'
			: 'https://id.stg.internal.atlassian.com/login';
	const product = getProduct(productType);

	return `${baseUrl}?continue=${encodeURIComponent(continueUrl)}${
		product ? `&application=${encodeURIComponent(product)}` : ''
	}
  ${loginHint ? `&login_hint=${encodeURIComponent(loginHint)}` : ''}`;
};
