import { type JoinableSitesDataProvider } from './cross-join/providers/joinable-sites-data-provider';
import { type SwitcherItemType } from './common/utils/links';
import type React from 'react';
import { type ProviderResult } from './common/providers/as-data-provider';
import { type AppIconKey } from './ui/primitives/app-icon';

export interface WithCloudId {
	cloudId: string;
}

export type DiscoverySection = JSX.Element;

export interface ContainerDetails {
	id: string;
	key: string;
	name: string;
	url: string;
	iconUrl: string;
}

export interface CustomLink {
	key: string;
	label: string;
	link: string;
	local: boolean;
}

export enum Permissions {
	MANAGE = 'manage',
	CAN_INVITE_USERS = 'invite-users',
}

export enum Product {
	BITBUCKET = 'bitbucket',
	CONFLUENCE = 'confluence',
	HOME = 'home',
	JIRA = 'jira',
	SITE_ADMIN = 'site-admin',
	TRELLO = 'trello',
	START = 'start',
}

export enum Feature {
	isUFOEnabled = 'isUFOEnabled',
}

export type FeatureFlagProps = {
	/** Enable experience tracking with UFO */
	isUFOEnabled?: boolean;
};

export type FeatureMap = { [key in Feature]: boolean };

export type CustomLinksResponse = CustomLink[];

export interface CurrentSite {
	url: string;
	products: AvailableProduct[];
}

export interface UserSiteDataResponse {
	currentSite: CurrentSite;
}

export interface UserPermissionResponse {
	permitted: boolean;
}

// The product keys need to match registered products in Flight Deck:
// https://bitbucket.org/atlassian/flight-deck/src/master/packages/integrations/twp-switch-to-products/contrib/
type FlightDeckProductType =
	| 'ANALYTICS'
	| 'BEACON'
	| 'BITBUCKET'
	| 'COMPASS'
	| 'CONFLUENCE'
	| 'JIRA_CUSTOMER_SERVICE'
	| 'JIRA_PRODUCT_DISCOVERY'
	| 'JIRA_SERVICE_DESK'
	| 'JIRA_SOFTWARE'
	| 'JIRA_BUSINESS'
	| 'mercury'
	| 'OPSGENIE'
	| 'ROVO'
	| 'STATUSPAGE'
	| 'TEAM_CENTRAL'
	| 'TRELLO'
	| 'LOOM';

// those product keys are only used internally in the app
type InternalProductType =
	| 'ATLASSIAN_HOME'
	| 'ASSETS'
	| 'COMPANY_HUB'
	| 'TEAMS'
	| 'GOALS'
	| 'SEARCH'
	| 'CHAT'
	| 'STUDIO';

export type SwitcherProductType = FlightDeckProductType | InternalProductType;

export type SwitcherLinkType = 'ADMINISTRATION' | string;

export enum AnalyticsItemType {
	PRODUCT = 'product',
	ADMIN = 'admin',
	TRY = 'try',
	JOIN = 'join',
	CUSTOM_LINK = 'customLink',
	EMPTY_STATE_EXPLORE_PRODUCTS = 'empty-state-explore-products',
}

export interface Organisation {
	id: string;
	displayName: string;
}

export type AvailableProduct = {
	productType: SwitcherProductType;
	url?: string;
	autoProvisionDate?: string;
	firstActiveDate?: string;
	activationDate?: string;
	endUserActivationDate?: string;
	adminActivationDate?: string;
	isJsmPremium?: boolean;
	isCompanyHubPublishedAndPremium?: boolean;
	companyHubName?: string;
};

export interface AvailableSite {
	adminAccess: boolean;
	availableProducts: AvailableProduct[];
	avatar: string | null;
	cloudId?: string;
	displayName: string;
	url: string;
	org?: Organisation;
}

export interface AvailableLink {
	linkType: string;
	url: string;
}

export interface AvailableProductsResponse {
	startUrl?: string;
	sites: AvailableSite[];
	links: AvailableLink[];
	isPartial: boolean;
	user?: {
		email: string | undefined;
	};
	assetsAsAnAppEnabled?: boolean;
	companyHubAsAnAppEnabled?: boolean;
	isScasAsAppEnabled?: boolean;
	isTeamsAsAppEnabled?: boolean;
	isGoalsAsAppEnabled?: boolean;
	isHomeRebrandEnabled?: boolean;
	isAnalyticsRebrandEnabled?: boolean;
	isTeam25ReorderEnabled?: boolean;
	switcherTeam25ExperimentCohort?: 'control' | 'test' | 'not-enrolled';
}

export interface JoinableSiteUser {
	avatarUrl: string;
	displayName: string;
	relevance?: number;
}

export interface JoinableSiteUserAvatarPropTypes {
	name: string;
	src: string;
	appearance: 'circle';
	size: 'small';
}

export interface JoinableProductDetails {
	collaborators: JoinableSiteUser[];
	productUrl: string;
	capability?: 'DIRECT_ACCESS' | 'REQUEST_ACCESS';
}

export interface JoinableProductsWithProductUrl {
	[key: string]: JoinableProductDetails;
}

export interface JoinableProductsWithUserIds {
	[key: string]: string[];
}

export interface JoinableSiteUsersKeyedByProduct {
	[key: string]: JoinableSiteUser[];
}

export type JoinableProducts = JoinableProductsWithProductUrl | JoinableProductsWithUserIds;

export interface JoinableSiteWithProducts {
	products: JoinableProducts;
	users?: JoinableSiteUsersKeyedByProduct;
}

export interface JoinableSiteWithUsers {
	products?: JoinableProducts;
	users: JoinableSiteUsersKeyedByProduct;
}

export type JoinableSite = {
	cloudId: string;
	displayName: string;
	url: string;
	avatarUrl?: string;
	relevance?: number;
} & (JoinableSiteWithProducts | JoinableSiteWithUsers);

export interface JoinableSitesResponse {
	sites: JoinableSite[] | undefined;
}

export enum ProductKey {
	CONFLUENCE = 'confluence.ondemand',
	JIRA_CORE = 'jira-core.ondemand',
	JIRA_SOFTWARE = 'jira-software.ondemand',
	JIRA_SERVICE_DESK = 'jira-servicedesk.ondemand',
	BITBUCKET = 'bitbucket',
	OPSGENIE = 'opsgenie',
	STATUSPAGE = 'statuspage',
	TRELLO = 'trello',
	COMPASS = 'compass',
	TEAM_CENTRAL = 'townsquare',
	JIRA_PRODUCT_DISCOVERY = 'jira-product-discovery',
}

export interface SwitcherChildItem {
	href: string;
	label: string;
	avatar: string | null;
}

export interface JoinableSiteClickHandlerData {
	availableProducts: SwitcherItemType[];
	event: React.SyntheticEvent;
	cloudId: string;
	href?: string;
	productType?: SwitcherProductType;
}

export interface JoinableSiteClickHandler {
	(data: JoinableSiteClickHandlerData): void;
}

export type AvailableProductDetails = Pick<
	SwitcherItemType,
	| 'iconKey'
	| 'key'
	| 'href'
	| 'label'
	| 'description'
	| 'iconUrl'
	| 'shouldShowNewLozenge'
	| 'autoProvisionDate'
	| 'isProductInactive'
> & {
	ordinal: number;
	rebrandedLabel?: JSX.Element | string;
	rebrandedIconKey?: AppIconKey;
};

export type AvailableProductLinks = AvailableProductDetails;

export type ProductConfigurationProductIcon = {
	blue: string;
	neutral: string;
	white: string;
};

type ProductConfigurationProduct = {
	apsKey: string;
	tenantlessProductAri?: string;
	tcsProductKey?: string;
	siteUrlSuffix?: string;
	displayName: string;
	rebrandedDisplayName?: string;
	icons: ProductConfigurationProductIcon;
	rebrandedIcons?: ProductConfigurationProductIcon;
	ordinal: number;
	tenantlessUrl?: string;
	subtitle: i18nLabel | null;
	iconKey: AppIconKey;
	rebrandedIconKey?: AppIconKey;
};

type ProductConfigurationLink = {
	apsKey: string;
	displayName: i18nLabel;
	iconUrl: string;
	ordinal: number;
	url: string;
	subtitle: i18nLabel | null;
};

export type FlightDeckProductConfigurationArtifact = {
	flightDeckAssetsBaseUrl: string;
	products: ProductConfigurationProduct[];
	links: ProductConfigurationLink[];
};

export type ProductConfigurationMap = Record<SwitcherProductType, AvailableProductDetails>;

export type ProductConfigurationResponse = {
	products: ProductConfigurationMap;
	links: Record<SwitcherLinkType, AvailableProductLinks>;
};

export interface ProviderResults {
	productConfiguration: ProviderResult<ProductConfigurationResponse>;
	availableProducts: ProviderResult<AvailableProductsResponse>;
	joinableSites: ProviderResult<JoinableSitesResponse>;
	managePermission: ProviderResult<boolean>;
	customLinks?: ProviderResult<CustomLinksResponse>;
}

export interface SyntheticProviderResults {
	userSiteData: ProviderResult<UserSiteDataResponse>;
}

export type SwitcherCallbackProps = {
	// Optional function allowing to close the switcher, e.g. after a joinable site link is clicked
	onClose?: () => void;
	// Optional callback to be executed for adding Origin Tracing info onto switcher links.
	customizeLinks?: CustomizeLinks;
};

export type SwitcherDataProviderProps = {
	// Optional custom provider for joinable sites
	joinableSitesDataProvider?: JoinableSitesDataProvider;
};

export interface GenericShowFlag<T> {
	(arg: T): T;
}

export type SwitcherPrimitiveProps = {
	// Product name used for analytics events
	product: string;
	// Optional cloudID, should be provided for tenanted applications.
	cloudId?: string;
	// Optional ability to highlight a joinable site
	highlightedJoinableItemHref?: string;
	// Optional admin URl (required for Statuspage)
	adminUrl?: string;
	/** A post office discovery section to be injected */
	discoverySection?: DiscoverySection;
	/* show flag function to consume and display product flags */
	showFlag?: GenericShowFlag<any>;
};

export type SwitcherSimpleProps = SwitcherPrimitiveProps & FeatureFlagProps;

export type AtlassianSwitcherProps = SwitcherDataProviderProps &
	SwitcherCallbackProps &
	SwitcherSimpleProps;

export interface AnalyticAttributes {
	[key: string]: any;
}

export interface MapUrl {
	(url: string, product: SwitcherProductType): string;
}

export interface GetExtendedAnalyticsAttributes {
	(product?: SwitcherProductType): AnalyticAttributes;
}

export interface CustomizeLinks {
	(): {
		// Provide function to update switcher url
		mapUrl: MapUrl;
		// Provides function to return additonal AnalyticAttributes
		getExtendedAnalyticsAttributes: GetExtendedAnalyticsAttributes;
	};
}

//
export interface ProductRecommendationsResponse {
	capability: ProductRecommendationsCapabilityProperty;
}

export type ProductRecommendationsCapabilityProperty = {
	REQUEST_ACCESS: Array<ProductRecommendationResource>;
	DIRECT_ACCESS: Array<ProductRecommendationResource>;
};

export type ProductRecommendationResourceWithCapability = ProductRecommendationResource & {
	capability: 'DIRECT_ACCESS' | 'REQUEST_ACCESS';
};

export type ProductRecommendationResource = {
	resourceId: string; //"ari:cloud:statuspage::site/bd98310f-491e-4af3-89a1-a23c6a9db606",
	userAccessLevel: string; //"INTERNAL",
	roleAri: string; //"ari:cloud:statuspage::role/product/member",
	url: string; //"https://manage.statuspagestaging.com/cloud/bd98310f-491e-4af3-89a1-a23c6a9db606",
	displayName: string; //"recommendations1",
	avatarUrl: string; //"https://site-admin-avatar-cdn.staging.public.atl-paas.net/avatars/240/lightbulb.png"
};

export type IconThemes = 'default' | 'product' | 'subtle';

export type i18nLabel = {
	id: string;
	defaultMessage: string;
	description: string;
};
