const INFINITY = '+Inf';

export const RenderTrackerBuckets = [1000, 900, 800, 700, 600, 500];

// Group load times in to buckets for metric cardinality
export const getMetricBucket = (metricValue: number, buckets: number[]): string | null => {
	return metricValue >= 0
		? buckets.reduce((lowestBucket, currentBucket) => {
				return metricValue <= currentBucket ? currentBucket.toString() : lowestBucket.toString();
			}, INFINITY)
		: null;
};
