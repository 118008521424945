import {
	createProviderWithCustomFetchData,
	type ExportedDataProvider,
} from '../../common/providers/create-data-provider';
import { type JoinableSitesResponse } from '../../types';
import { type ProviderRetryConfig } from '../../common/providers/as-data-provider';
import { ExperienceMark } from '../../common/utils/experience-tracker';

export type JoinableSiteDataFetcher = () => Promise<JoinableSitesResponse>;

export type JoinableSiteDataFetcherWithCollaborators = ({
	cloudId,
}: {
	cloudId?: string;
}) => Promise<JoinableSitesResponse>;

export const fetchEmptyData: JoinableSiteDataFetcher = () => Promise.resolve({ sites: undefined });

export const createJoinableSitesProvider = (
	fetchData: JoinableSiteDataFetcherWithCollaborators = fetchEmptyData,
	retryConfig?: ProviderRetryConfig,
): ExportedDataProvider<JoinableSitesResponse> => {
	return createProviderWithCustomFetchData<JoinableSitesResponse, { cloudId?: string }>(
		'joinableSites',
		ExperienceMark.API_JOINABLE_SITES,
		fetchData,
		retryConfig,
	);
};
