import { useRef } from 'react';

export function useSingleton<T>(builder: () => T): T {
	const ref = useRef<T>();
	if (!ref.current) {
		ref.current = builder();
	}
	return ref.current;
}

/**
 * Utility hook which allows us to execute callbacks immediately on component mount construction.
 * @see https://projects.wojtekmaj.pl/react-lifecycle-methods-diagram/
 *
 * We need this as `useEffect`/`useLayoutEffect` executes on a bottom-up/child-first order,
 * which will execute side effectful code in an order we might not want.
 * @example
 * <Foo> // sideEffect1()
 *    <Bar/> // sideEffect2()
 * </Foo>
 */
export function useOnceImmediately<T>(callback: () => T) {
	const wasCalledRef = useRef(false);
	const result = useRef<T>();
	if (!wasCalledRef.current) {
		wasCalledRef.current = true;
		result.current = callback();
	}
	return result.current as T;
}
